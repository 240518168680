<template>
  <base-section id="k-d-a-home-quote" space="0">
    <base-img :src="require('@/assets/bg/bg-home-quote.png')">
      <v-container class="pa-0 fill-height">
        <v-row style="padding: 144px 40px">
          <v-col>
            <component
              :is="m_objContent.strComponent"
              :class="`kda-ts-${
                g_bLowerBr ? '20' : '36'
              }pt ln-160 wt-extrabold font-italic mx-auto`"
              style="max-width: 1038px"
              v-html="m_objContent.htmlText"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAHomeQuote',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_objContent() {
      const firstText = this.$vuetify.lang.t('$vuetify.kda.home.quote.before');
      const secondText = this.$vuetify.lang.t('$vuetify.kda.home.quote.after');

      return {
        htmlText: `${firstText}<span class="main-r-400--text">KeDA Tech</span>${secondText}`,
        strComponent: 'p'
      };
    }
  }
};
</script>
